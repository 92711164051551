<template>
  <div class="d-flex justify-center flex-column flex-lg-row flex-xl-row">
    <v-select
      label="Versión de Campaña por región"
      :value="costItem.campaignId"
      class="mr-2"
      :items="selectableCampaigns"
      item-text="displayName"
      item-value="id"
      outlined
      dense
    />

    <v-text-field
      label="Insumo/Servicio"
      outlined
      dense
      filled
      class="managrx-input mr-2"
      :value="costItem.directSliceCostItemName"
      readonly
    ></v-text-field>

    <v-text-field
      label="Precio"
      class="managrx-input mr-2"
      v-model.number="price"
      type="number"
      outlined
      dense
    ></v-text-field>

    <v-text-field
      label="Fecha"
      class="managrx-input mr-2"
      :value="costItem.date.slice(0, 10)"
      outlined
      dense
      readonly
      filled
    ></v-text-field>

    <v-select
      label="Restriccion Region"
      v-if="costItem.hasRegionVariants"
      :value="costItem.regionId"
      class="mr-2"
      :items="selectableRegions"
      item-text="name"
      item-value="id"
      outlined
      dense
      filled
      readonly
    >
    </v-select>

    <v-select
      label="Restriccion Actividad"
      v-if="costItem.hasActivityVariants"
      :value="costItem.activityId"
      class="mr-2"
      :items="selectableActivities"
      item-text="name"
      item-value="id"
      outlined
      dense
      filled
      readonly
    >
    </v-select>

    <v-btn
      @click="savePrice"
      elevation="0"
      color="white--text primary"
      class="mr-2 mb-2"
      :disabled="price === costItem.price"
    >
      <v-icon> mdi-floppy </v-icon>
    </v-btn>
    <v-btn
      @click="deleteItem"
      elevation="0"
      color="white--text red accent-2"
      class="mr-2 mb-2"
    >
      <v-icon> mdi-trash-can </v-icon>
    </v-btn>
  </div>
</template>

<style scoped></style>

<script>
import useSelectables from "../../mixins/useSelectables";
import { putRequest, deleteRequest } from "../../managrx/serverCall";
export default {
  name: "ListedCostItemElement",
  mixins: [useSelectables],
  mounted() {
    this.price = this.costItem.price;
  },
  props: {
    costItem: Object,
  },
  methods: {
    async savePrice() {
      this.$store.dispatch("setPageLoadingStatus", true);
      let payload = {};
      if (this.price !== this.costItem.price) {
        payload.price = this.price;
        let response = await putRequest(
          "/listed_direct_slice_cost_item/" +
            this.costItem.listedDirectSliceCostItemId,
          payload
        );
        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        }
        this.$emit("rowSave");
      }
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    async deleteItem() {
      this.$store.dispatch("setPageLoadingStatus", true);
      let id = this.costItem.listedDirectSliceCostItemId;
      try {
        let response = await deleteRequest(
          `/listed_direct_slice_cost_item/${id}`
        );

        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          this.$emit("itemDelete", this.costItem);
        }
      } catch (error) {
        let notification = {
          type: "error",
          text: `${error?.response?.data?.error}`,
        };
        this.$store.dispatch("setNewNotification", notification);
      }
      this.$store.dispatch("setPageLoadingStatus", false);
    },
  },
  data() {
    return {
      price: null,
    };
  },
  computed: {},
};
</script>
