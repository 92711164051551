<template>
  <div>
    <v-form ref="form" class="pr-2 pl-2">
      <div class="d-flex flex-column">
        <v-select
          label="Versión de Campaña por región"
          v-model="newListedCostItem.campaignId"
          class="mr-2"
          :items="selectableCampaigns"
          item-text="displayName"
          :rules="required"
          item-value="id"
          outlined
          dense
        />

        <v-text-field
          label="Insumo/Servicio"
          outlined
          dense
          filled
          class="managrx-input mr-2"
          :value="costItemName"
          :rules="required"
          readonly
        ></v-text-field>

        <v-text-field
          label="Precio"
           
          class="managrx-input mr-2"
          type="number"
          v-model.number="newListedCostItem.price"
          :rules="required"
          outlined
          dense
        ></v-text-field>

        <v-select
          label="Restriccion Region"
          v-if="costItemHasRegionVariants"
          v-model="newListedCostItem.regionId"
          class="mr-2"
          :items="selectableRegions"
          :rules="required"
          item-text="name"
          item-value="id"
          outlined
          dense
        >
        </v-select>

        <v-select
          label="Restriccion Actividad"
          v-if="costItemHasActivityVariants"
          v-model="newListedCostItem.activityId"
          class="mr-2"
          :items="selectableActivities"
          item-text="name"
          item-value="id"
          :rules="required"
          outlined
          dense
        >
        </v-select>
        <v-btn color="primary" @click="saveNewListedDirectSliceCostItem">
          <v-icon>mdi-floppy</v-icon>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import useSelectables from "../../mixins/useSelectables";
import { postRequest } from "@/managrx/serverCall";

function makeEmptyNewListedCostItem() {
  return {
    campaignId: this.selectedCampaignId,
    price: null,
    regionId: null,
    activityId: null,
  };
}
export default {
  name: "NewListedCostItem",
  mixins: [useSelectables],
  props: {
    selectedCampaignId: Number,
    directSliceCostItemId: Number,
    costItem: Object,
  },
  watch: {
    selectedCampaignId: function (newVal) {
      // Watch prop for update
      this.newListedCostItem.campaignId = newVal
    },
  },
  computed: {
    required() {
      return [(v) => !!v || "Campo obligatorio"];
    },
    costItemName() {
      return this?.costItem?.name;
    },
    costItemHasRegionVariants() {
      return this?.costItem?.hasRegionVariants;
    },
    costItemHasActivityVariants() {
      return this?.costItem?.hasActivityVariants;
    },
  },
  methods: {
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async saveNewListedDirectSliceCostItem() {
      let payload = {
        ...this.newListedCostItem,
        directSliceCostItemId: this.costItem.id,
        date: new Date().toISOString(),
      };
      if (this.$refs.form.validate()) {
        let response = await postRequest(
          `/listed_direct_slice_cost_item`,
          payload
        );

        if (!response.success) {
          this.$store.dispatch("setNewNotification", response);
        } else {
          this.$emit("postSuccess", response.data);

          this.newListedCostItem = makeEmptyNewListedCostItem();
          this.resetValidation();
        }
      } else {
        if (!payload.campaignId) {
          let msg = `La versión de campaña no puede ser nula`;
          let notif = {
            success: false,
            data: {
              type: "alert",
              text: msg,
            },
          };
          this.$store.dispatch("setNewNotification", notif);
        }

        if (this.costItem.hasRegionVariants && !payload.regionId) {
          let msg = `La region no puede ser nula`;
          let notif = {
            success: false,
            data: {
              type: "alert",
              text: msg,
            },
          };
          this.$store.dispatch("setNewNotification", notif);
        }

        if (this.costItem.hasActivityVariants && !payload.activityId) {
          let msg = `La actividad seleccionada no puede ser nula`;
          let notif = {
            success: false,
            data: {
              type: "alert",
              text: msg,
            },
          };
          this.$store.dispatch("setNewNotification", notif);
        }
      }
    },
  },
  data() {
    return {
      newListedCostItem: {
        campaignId: this.selectedCampaignId,
        price: null,
        regionId: null,
        activityId: null,
      },
    };
  },
};
</script>
